import { type Theme } from '@astral/ui';

import { css } from '../styled';

export const makeGlobalThemeStyles = (theme: Theme) => css`
  :root {
    --primary900: ${theme.palette.primary[900]};
    --primary800: ${theme.palette.primary[800]};
    --primary700: ${theme.palette.primary[700]};
    --primary600: ${theme.palette.primary[600]};
    --primary500: ${theme.palette.primary[500]};
    --primary400: ${theme.palette.primary[400]};
    --primary300: ${theme.palette.primary[300]};
    --primary200: ${theme.palette.primary[200]};
    --primary100: ${theme.palette.primary[100]};
  }
`;
