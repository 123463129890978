import { observer } from 'mobx-react-lite';
import { type ReactNode } from 'react';

import { OverflowTypography } from '../../../OverflowTypography';
import { ActionButton } from '../../../ActionButton';
import { EditOutlineMd } from '../../../Icons';

import { TextCellWrapper } from './styles';

export type CellTitleAndActionProps = {
  /**
   * текст отображаемый в тултипе кнопки
   */
  actionTooltipText: string;
  text?: string | ReactNode;
  onClick: () => void;
  isEditAlwaysVisible?: boolean;
};

/**
 * вспомогательный компонент для отрисовки текста и кнопки переключения
 */
export const CellTitleAndAction = observer(
  ({
    text,
    onClick,
    actionTooltipText,
    isEditAlwaysVisible,
  }: CellTitleAndActionProps) => (
    <TextCellWrapper $isEditAlwaysVisible={isEditAlwaysVisible}>
      {typeof text === 'string' ? (
        <OverflowTypography>{text}</OverflowTypography>
      ) : (
        text
      )}
      <ActionButton
        icon={<EditOutlineMd />}
        tooltipContent={actionTooltipText}
        disableInteractiveInTooltip
        onClick={onClick}
      />
    </TextCellWrapper>
  ),
);
