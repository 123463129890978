import { styled } from '../../styled';
import { SvgIcon } from '../../SvgIcon';

export const Wrapper = styled(SvgIcon)<{ $isAsc: boolean; $isActive: boolean }>`
  --top: ${({ theme, $isActive, $isAsc }) =>
    $isActive && $isAsc
      ? theme.palette.primary.dark
      : theme.palette.primary.light};
  --bot: ${({ theme, $isActive, $isAsc }) =>
    $isActive && !$isAsc
      ? theme.palette.primary.dark
      : theme.palette.primary.light};
`;
