/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAffiliateDto } from '../models/AddAffiliateDto';
import type { AffiliateDto } from '../models/AffiliateDto';
import type { GetAffiliateListOrderBy } from '../models/GetAffiliateListOrderBy';
import type { SetAffiliateIntegrationStateDto } from '../models/SetAffiliateIntegrationStateDto';
import type { UpdateAffiliateDto } from '../models/UpdateAffiliateDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class AffiliatesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns AffiliateDto
   * @throws ApiError
   */
  public affiliatesGetAffiliatesList({
    findText,
    orderBy,
    isDesc,
    offset,
    count,
  }: {
    findText?: string | null;
    orderBy?: GetAffiliateListOrderBy;
    isDesc?: boolean;
    offset?: number;
    count?: number;
  }): CancelablePromise<Array<AffiliateDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/tenantmgmt/Affiliates',
      query: {
        FindText: findText,
        OrderBy: orderBy,
        IsDesc: isDesc,
        Offset: offset,
        Count: count,
      },
    });
  }

  /**
   * @returns string
   * @throws ApiError
   */
  public affiliatesAddAffiliate({
    requestBody,
  }: {
    requestBody: AddAffiliateDto;
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/tenantmgmt/Affiliates',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AffiliateDto
   * @throws ApiError
   */
  public affiliatesGetAffiliateById({
    affiliateId,
  }: {
    affiliateId: string;
  }): CancelablePromise<AffiliateDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/tenantmgmt/Affiliates/{affiliateId}',
      path: {
        affiliateId: affiliateId,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public affiliatesUpdateAffiliate({
    affiliateId,
    requestBody,
  }: {
    affiliateId: string;
    requestBody: UpdateAffiliateDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/tenantmgmt/Affiliates/{affiliateId}',
      path: {
        affiliateId: affiliateId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public affiliatesSetAffiliateIntegrationState({
    affiliateId,
    requestBody,
  }: {
    affiliateId: string;
    requestBody: SetAffiliateIntegrationStateDto;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/tenantmgmt/Affiliates/{affiliateId}/IntegrationActivated',
      path: {
        affiliateId: affiliateId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
