import { useEffect, useId } from 'react';
import {
  SwipeableDrawer as UISwipeableDrawer,
  type SwipeableDrawerProps as UISwipeableDrawerProps,
} from '@astral/ui';
import { pageLocker } from '@services/pageLocker';

type SwipeableDrawerProps = UISwipeableDrawerProps;

export const SwipeableDrawer = ({ ...props }: SwipeableDrawerProps) => {
  const { open } = props;
  const id = useId();

  useEffect(() => {
    if (open) {
      pageLocker.lock(id);
    } else {
      pageLocker.unlock(id);
    }

    return () => pageLocker.unlock(id);
  }, [open]);

  return <UISwipeableDrawer {...props} />;
};
