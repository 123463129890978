import { observer } from 'mobx-react-lite';

import {
  AppLogoIllustration,
  DashboardLayout,
  PlaceholderImage,
  type WithJSXChildren,
  routerService,
} from '@dashboard/shared';

import { MENU } from './constants';

export const CommonLayout = observer(({ children }: WithJSXChildren) => (
  <DashboardLayout>
    <DashboardLayout.Header
      product={{
        name: 'Панель администратора',
        logo: () => (
          <PlaceholderImage src={AppLogoIllustration} width="20" height="20" />
        ),
      }}
    />
    <DashboardLayout.Sidebar menu={MENU(routerService.pathname)} />
    <DashboardLayout.Main>{children}</DashboardLayout.Main>
  </DashboardLayout>
));
