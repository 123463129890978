import { ConfigFetcher as CommonConfigFetcher } from '@common/api';
import { type CacheService } from '@shared';

import { cacheService } from '../../services';

/**
 * Фетчер для получения конфига
 */
export class ConfigFetcher<TConfig> extends CommonConfigFetcher<TConfig> {
  private static instance: ConfigFetcher<{}> | null = null;

  private constructor(_cacheService: CacheService = cacheService) {
    super(_cacheService);
  }

  public static getInstance<TConfig>() {
    if (this.instance === null) {
      this.instance = new ConfigFetcher();
    }

    return this.instance as unknown as ConfigFetcher<TConfig>;
  }
}
