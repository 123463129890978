import { makeAutoObservable } from 'mobx';
import { serializeError } from 'serialize-error';

type OnSuccessLoadArgs = {
  numPages: number;
};

type PdfReaderStoreParams = {
  initialPage?: number;
  errorText: string;
};

const checkMedia = (string: string) => globalThis?.matchMedia(string).matches;

export class PdfReaderStore {
  /**
   * колличество страниц
   */
  public numPages = 0;

  /**
   * номер текущей страницы
   */
  public page = 0;

  /**
   * флаг, обозначающий, что react-pdf что-то делает
   */
  public isLoading = true;

  /**
   * флаг, обозначающий, что react-pdf не смог
   */
  public isError = false;

  /**
   * текст ошибки
   */
  public error: string;

  constructor(private readonly params: PdfReaderStoreParams) {
    this.error = params.errorText;
    this.page = params.initialPage ?? 1;
    makeAutoObservable(this);
  }

  /**
   * метод, вызываемый react-pdf, когда документ успешно отрендерен
   */
  public onDocumentLoadSuccess = ({ numPages }: OnSuccessLoadArgs) => {
    this.numPages = numPages;
    this.isLoading = false;
    this.isError = false;
  };

  /**
   * обработчик ошибки при рендере и загрузке документа в react-pdf
   */
  public onError = (error: Error) => {
    if (error) {
      const err = serializeError(error);

      if (err.message) {
        this.error = this.params.errorText;
      }

      this.isError = true;
    }
  };

  /**
   * обработчик смены страницы,
   * предполагается, что тут не должно происходить смены страницы,
   * лишь дергаем скролл, а intersectionObserver уже переключит сам номер страницы
   */
  public onPageChange = (value: number, scrollRoot: HTMLElement | null) => {
    if (scrollRoot) {
      const page = scrollRoot.querySelector(`.pageItem:nth-child(${value})`);

      if (page) {
        scrollRoot.focus();

        page.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'start',
        });
      }
    }
  };

  /**
   * массив, для генерации страниц во вьюшке
   */
  public get pages(): number[] {
    return Array.from({ length: this.numPages }, (_, index) => index);
  }

  /**
   * обсервер, который следит за попаданием страниц во вьюпорт
   */
  private intersectionObserver?: IntersectionObserver = undefined;

  /**
   * метод инициализации intersectionObserver
   */
  private initIntersectionObserver = (scrollRoot: HTMLDivElement) => {
    if (!this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const number = entry.target.getAttribute('data-pagenumber');

            if (entry.isIntersecting && number) {
              this.page = parseInt(number);
            }
          });
        },
        // 0.7 означает, что если страница будет видно во вьюпорте больше чем на 70%,
        // то тогда entry.isIntersecting будет true
        { threshold: 0.2, root: scrollRoot, rootMargin: '-10%' },
      );
    }
  };

  /**
   * множитель для скейла канваса
   */
  public scale = 1;

  public onResize = () => {
    const isBiggerThanXl = checkMedia('(min-width: 1536px');
    const isLessThanLg = checkMedia('(max-width: 1200px');

    this.scale = (isBiggerThanXl && 1.5) || (isLessThanLg && 1) || 1.25;
  };

  /**
   * метод для добавления страницы к intersectionObserver
   */
  public observeNode = (node: HTMLDivElement, scrollRoot: HTMLDivElement) => {
    this.initIntersectionObserver(scrollRoot);

    // делаем небольшую паузу для подписки на изменения,
    // чтобы дать браузеру времени на отрисовку страницы,
    // иначе получим лишние вызовы изменений intersectionObserver
    setTimeout(() => {
      this.intersectionObserver?.observe(node);
    }, 100);
  };

  /**
   * метод длч исключения страницы из intersectionObserver
   */
  public unobseverNode = (node: HTMLDivElement) => {
    this.intersectionObserver?.unobserve(node);
  };

  /**
   * метод для очистки памяти, предполагается использование на unmount
   */
  public destroy = () => {
    this.intersectionObserver?.disconnect();
  };
}

type CreatePdfReaderStoreParams = PdfReaderStoreParams;

export const createPdfReaderStore = (params: CreatePdfReaderStoreParams) =>
  new PdfReaderStore(params);
