import { type FieldValues, useController } from 'react-hook-form';
import { DatePicker, useBackdropStackToggle } from '@astral/ui';
import { useMemo } from 'react';
import { classnames } from '@utils/string/classnames';
import { METRIKA_CLASSES } from '@constants/metrikaClasses';
import { MIN_DATE } from '@constants/form/date';
import { isDate } from '@services/DateService/utils/isDate';

import { type FormDatepickerProps } from './types';

// небольшой костылик, наш бэк отдает дату без Z, а для new Date() это невалидная строковая дата
const regExpIsoWithoutZ = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d$/;

const normalizeStringDate = <FormValues extends FieldValues>(
  date: FormValues,
) => {
  if (typeof date === 'string' && (date as string).match(regExpIsoWithoutZ)) {
    return `${date}Z`;
  }

  return date;
};

export function FormDatepicker<FormValues extends FieldValues>({
  minDate = MIN_DATE,
  maxDate,
  disabled,
  onClose,
  hidePersonalData = false,
  ...props
}: FormDatepickerProps<FormValues>) {
  const { handleClose, handleOpen } = useBackdropStackToggle();
  const { field, fieldState } = useController(props);
  const { onChange, value, ...restField } = field;

  const errorMessages = fieldState.error?.message;
  const hasError = Boolean(errorMessages);
  const handleChange = (date?: unknown) => onChange(isDate(date) ? date : '');

  const initialValue = useMemo(() => {
    if (value) {
      return new Date(normalizeStringDate(value));
    }

    return undefined;
  }, [value]);

  return (
    <DatePicker
      {...restField}
      onOpen={handleOpen}
      onClose={onClose || handleClose}
      value={initialValue}
      onChange={handleChange}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      inputProps={{
        ...props,
        error: hasError,
        helperText: hasError && errorMessages,
        fullWidth: true,
        //@ts-ignore TODO: обновить UIKIT и убрать, когда закроется задача UIKIT-1741
        autoComplete: 'off',
        className: classnames({
          [METRIKA_CLASSES.hideInputValue]: hidePersonalData,
        }),
      }}
    />
  );
}
