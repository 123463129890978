import { type EndpointParams, type ReplaceRequestBody } from '@shared';

import { cacheService } from '../../services';
import { identityClient } from '../clients';

export const authFetcher = {
  login: cacheService.createMutationSet(
    (
      params: ReplaceRequestBody<
        EndpointParams<typeof identityClient.esa.esaLogin>
      >,
    ) => identityClient.esa.esaLogin({ requestBody: params }),
  ),
  refresh: cacheService.createMutationSet(
    (params: EndpointParams<typeof identityClient.esa.esaRefreshToken>) =>
      identityClient.esa.esaRefreshToken(params),
  ),
};

export type AuthFetcher = typeof authFetcher;
