import { css, styled } from '../../../styled';

const hoverStyles = css`
  & button {
    transition:
      opacity 0.3s linear,
      visibility 0.3s linear;
  }

  &:not(:hover) button {
    visibility: hidden;
    opacity: 0;
  }
`;

export const TextCellWrapper = styled.div<{ $isEditAlwaysVisible?: boolean }>`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;

  ${({ $isEditAlwaysVisible }) => ($isEditAlwaysVisible ? '' : hoverStyles)}

  & > * {
    width: 100%;
  }

  & > .MuiBadge-root {
    flex-shrink: initial;
  }
`;
