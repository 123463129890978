import axios, { type AxiosInstance } from 'axios';

type EjectInterceptorsOptions = {
  axiosInterceptor: number;
  instanceInterceptor: number;
};

export class HttpInterceptor {
  constructor(private readonly instance: AxiosInstance) {}

  ejectRequestInterceptors({
    axiosInterceptor,
    instanceInterceptor,
  }: EjectInterceptorsOptions) {
    axios.interceptors.request.eject(axiosInterceptor);
    this.instance.interceptors.request.eject(instanceInterceptor);
  }

  ejectResponseInterceptors({
    axiosInterceptor,
    instanceInterceptor,
  }: EjectInterceptorsOptions) {
    axios.interceptors.response.eject(axiosInterceptor);
    this.instance.interceptors.response.eject(instanceInterceptor);
  }

  onRequest(...params: Parameters<typeof axios.interceptors.request.use>) {
    const axiosInterceptor = axios.interceptors.request.use(...params);
    const instanceInterceptor = this.instance.interceptors.request.use(
      ...params,
    );

    return { axiosInterceptor, instanceInterceptor };
  }

  onResponse(...params: Parameters<typeof axios.interceptors.response.use>) {
    const axiosInterceptor = axios.interceptors.response.use(...params);
    const instanceInterceptor = this.instance.interceptors.response.use(
      ...params,
    );

    return { axiosInterceptor, instanceInterceptor };
  }
}
