/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';
import type { OpenAPIConfig } from '@common/data/api-core/OpenAPI';
import { AxiosHttpRequest } from '@common/data/api-core/AxiosHttpRequest';

import { EmployeeWorkplacesService } from './services/EmployeeWorkplacesService';
import { EsaService } from './services/EsaService';
import { ExternalIntegrationTokensService } from './services/ExternalIntegrationTokensService';
import { PermissionPoliciesService } from './services/PermissionPoliciesService';
import { TenantTokensService } from './services/TenantTokensService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class IdentityClient {
  public readonly employeeWorkplaces: EmployeeWorkplacesService;
  public readonly esa: EsaService;
  public readonly externalIntegrationTokens: ExternalIntegrationTokensService;
  public readonly permissionPolicies: PermissionPoliciesService;
  public readonly tenantTokens: TenantTokensService;
  public readonly users: UsersService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '2.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.employeeWorkplaces = new EmployeeWorkplacesService(this.request);
    this.esa = new EsaService(this.request);
    this.externalIntegrationTokens = new ExternalIntegrationTokensService(
      this.request,
    );
    this.permissionPolicies = new PermissionPoliciesService(this.request);
    this.tenantTokens = new TenantTokensService(this.request);
    this.users = new UsersService(this.request);
  }
}
