import { makeAutoObservable, runInAction, when } from 'mobx';
import {
  type AxiosClient,
  type JwtTokens,
  type RouterService,
  type TransactionEvent,
  type TransactionOutbox,
  appPaths,
  axiosClient,
  routerService,
  searchParamsCreator,
  tokenParse,
  transactionOutbox,
} from '@shared';
import { type AuthFetcher, authFetcher } from '@api';

import {
  type UpdateTokensOptions,
  getAccessToken,
  getRefreshToken,
  updateTokens,
} from './utils';

const NOT_REDIRACTABLE_PATHNAMES = [
  appPaths.integrationTokens.newToken,
  appPaths.styleguide.base,
];

type AuthStoreParams = {
  onLogout?: () => void;
  getAccess: () => string | undefined;
  getRefresh: () => string | undefined;
  updateTokens: (data: UpdateTokensOptions) => void;
};

/**
 * стор обеспечивающий работу с jwt токентами в рамках дашборда
 * ответственный за хранение состояния логина и за обновление access через рефреш токен
 */
export class AuthStore {
  private refreshPromise?: Promise<{}>;

  public isLoggedIn: boolean = false;

  public userData: {} | null = null;

  private requestConfig: {
    axiosInterceptor: number;
    instanceInterceptor: number;
  } | null = null;

  private responseConfig: {
    axiosInterceptor: number;
    instanceInterceptor: number;
  } | null = null;

  constructor(
    private readonly _params: AuthStoreParams,
    private readonly _fetcher: AuthFetcher,
    private readonly _routerService: RouterService,
    private readonly _transactionOutbox: TransactionOutbox<TransactionEvent>,
    private readonly _axiosClient: AxiosClient,
  ) {
    this.checkData();
    this.setupInterceptors();
    makeAutoObservable(this);
  }

  private get interceptors() {
    return this._axiosClient.interceptors;
  }

  private setupInterceptors = () => {
    // перехватчик ответственный за ретрай, при истекших токенах
    this.responseConfig = this.interceptors.onResponse(
      (r) => r,
      (error) => {
        if (error !== null && error.response) {
          const code = (error.response.data as { code: string })?.code;

          if (
            code === 'access_token_error_decode' &&
            this._params.getRefresh()
          ) {
            return this.refresh().then(() =>
              this._axiosClient.instance({
                ...error.config,
                ...{ retry: true },
              }),
            );
          }
        }

        return Promise.reject(error);
      },
    );

    // перехватчик ответственный за обогащение запросов заголовками с авторизацией
    // при необходимости, паузит запрос до момента, пока пользователь на залогинится
    this.requestConfig = this.interceptors.onRequest(
      async (config) => {
        if (
          config?.url?.match(
            /identity\/Esa|identity\/ExternalIntegrationTokens\/New/,
          )
        ) {
          return config;
        }

        const hasAccessToken = Boolean(this._params.getAccess());
        const hasRefreshToken = Boolean(this._params.getRefresh());

        if (!hasAccessToken && hasRefreshToken) {
          await this.refresh();
        }

        if (!hasAccessToken && !hasRefreshToken) {
          runInAction(this.setLogout);
          await when(() => this.isLoggedIn);
        }

        if (this._params.getAccess()) {
          runInAction(() => {
            this.isLoggedIn = true;
            this.userData = tokenParse(this._params.getAccess() || '');
          });
        }

        config.headers = config.headers || {};
        config.headers['kedo-gateway-token-type'] = 'AdminApi';
        config.headers.Authorization = `Bearer ${this._params.getAccess()}`;

        return config;
      },
      (r) => {
        throw r;
      },
    );
  };

  private onLogin = (tokens: JwtTokens) => {
    this._params.updateTokens(tokens);
    this.checkData();

    return true;
  };

  /**
   * метод с проверкой на залогиненность и соответствующими реакциями
   */
  private checkData = () => {
    const initialUserData = this._params.getAccess();

    this.isLoggedIn = Boolean(initialUserData);

    if (initialUserData) {
      this.userData = tokenParse(initialUserData);
    } else {
      this._transactionOutbox.subscribe('login', this.onLogin);
    }

    if (
      !this.isLoggedIn &&
      !this._routerService.pathname.match(appPaths.auth.base)
    ) {
      const hasRefresh = this._params.getRefresh();

      if (hasRefresh) {
        this.refresh();
      } else {
        this.navigateToAuthPage();
      }
    }
  };

  /**
   * метод для редиркта пользователя на форму логина
   */
  private navigateToAuthPage = () => {
    const currentPathname = this._routerService.pathname;

    if (
      NOT_REDIRACTABLE_PATHNAMES.some((pathname) =>
        pathname.match(currentPathname),
      )
    ) {
      return;
    }

    this._routerService.navigate(appPaths.auth.base, {
      search: searchParamsCreator({
        redirectBack: currentPathname
          ? `${currentPathname}${globalThis.location.search}`
          : '',
      }),
    });
  };

  /**
   * метод логаута пользователя
   */
  private setLogout = () => {
    this.isLoggedIn = false;
    this.userData = null;
    this.navigateToAuthPage();
    this._params.onLogout?.();
  };

  private get refreshQuery() {
    return this._fetcher.refresh.create();
  }

  /**
   * метод для обновления токенов
   */
  private refresh = (): Promise<{}> => {
    if (!Boolean(this.refreshPromise)) {
      this.refreshPromise = this.refreshQuery
        .async({
          refreshToken: this._params.getRefresh(),
        })
        .then((res: JwtTokens) => {
          this._params.updateTokens(res);

          return true;
        })
        .catch((e) => {
          runInAction(this.setLogout);

          return Promise.reject(e);
        })
        .finally(() => {
          runInAction(() => {
            this.refreshPromise = undefined;
          });
        });
    }

    return this.refreshPromise!;
  };

  public destroy = () => {
    if (this.requestConfig) {
      this.interceptors.ejectRequestInterceptors(this.requestConfig);
    }

    if (this.responseConfig) {
      this.interceptors.ejectResponseInterceptors(this.responseConfig);
    }
  };
}

export const authStore = new AuthStore(
  {
    getAccess: getAccessToken,
    getRefresh: getRefreshToken,
    updateTokens,
  },
  authFetcher,
  routerService,
  transactionOutbox,
  axiosClient,
);
