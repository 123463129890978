import { useForm } from '@hooks/useForm';
import { createSchema, email, string } from '@schemas/schema';
import { type DateType } from '@services';

import { type FormCellCommonProps, type FormCellToggleProps } from '../types';
import { CellInner } from '../CellInner';
import { createSubmitHandler } from '../utils';
import { FormDatepicker } from '../../FormDatepicker';

const schema = createSchema<{ value: string }>({
  value: string(email()),
});

export type ExternalCellDatePickerlInnerProps = FormCellCommonProps<
  DateType | undefined | null
> & {
  validationSchema?: typeof schema;
};

type CellDatePickerlInnerProps = FormCellToggleProps &
  ExternalCellDatePickerlInnerProps;

/**
 * реализация внутренней части для емейла
 */
export const CellDatePickerlInner = ({
  onSubmit,
  value,
  isLoading,
  onSuccess,
  onCancel,
  validationSchema = schema,
}: CellDatePickerlInnerProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: { value: value },
    validateSchema: validationSchema,
    onSubmit: createSubmitHandler({ onSuccess, onSubmit }),
  });

  return (
    <CellInner
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onCancel={onCancel}
      isDirty={isDirty}
    >
      <FormDatepicker
        fullWidth
        size="small"
        aria-label="email"
        hiddenLabel
        control={control}
        name="value"
      />
    </CellInner>
  );
};
