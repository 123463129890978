import {
  ThemeProvider as AstralThemeProvider,
  type ThemeProviderProps,
} from '@astral/ui';

import { makeGlobalThemeStyles } from '../GlobalThemeStyles';
import { Global } from '../styled';

export const ThemeProvider = ({ children, ...props }: ThemeProviderProps) => (
  <AstralThemeProvider {...props}>
    <Global styles={makeGlobalThemeStyles(props.theme)} />
    {children}
  </AstralThemeProvider>
);
