const SYMBOL = '#main';

import Warn from './Warn.svg';
import Rocket from './Rocket.svg';
import RejectSuccess from './RejectSuccess.svg';
import OutgDocs from './OutgDocs.svg';
import SignError from './SignError.svg';
import DocumentError from './DocumentError.svg';
import NoMoreDocuments from './NoMoreDocuments.svg';
import PersonalArea from './PersonalArea.svg';
import Help from './Help.svg';
import Mail from './Mail.svg';
import CertificatesReady from './CertificatesReady.svg';
import NewDocs from './NewDocs.svg';
import AcceptanceAgreement from './AcceptanceAgreement.svg';
import Uploaded from './Uploaded.svg';
import SignSuccess from './SignSuccess.svg';
import Monitor from './Monitor.svg';
import File from './File.svg';
import Goal from './Goal.svg';
import Forbidden from './Forbidden.svg';
import UnknownDocument from './UnknownDocument.svg';
import IncomDocs from './IncomDocs.svg';
import Clock from './Clock.svg';
import EditDocument from './EditDocument.svg';
import Error from './Error.svg';
import Aside from './Aside.svg';
import DocumentPlaceholder from './DocumentPlaceholder.svg';
import RefreshPage from './RefreshPage.svg';
import NoResults from './NoResults.svg';
import WrongDocument from './WrongDocument.svg';
import ServerError from './ServerError.svg';
import Repair from './Repair.svg';
import NotFound from './NotFound.svg';
import NoLicense from './NoLicense.svg';
import LogoText from './LogoText.svg';
import AppLogo from './AppLogo.svg';
import AngledLogo from './AngledLogo.svg';

export const NoResultsIllustration = NoResults + SYMBOL;

export const WarnIllustration = Warn + SYMBOL;

export const RocketIllustration = Rocket + SYMBOL;

export const RejectSuccessIllustration = RejectSuccess + SYMBOL;

export const OutgDocsIllustration = OutgDocs + SYMBOL;

export const SignErrorIllustration = SignError + SYMBOL;

export const DocumentErrorIllustration = DocumentError + SYMBOL;

export const NoMoreDocumentsIllustration = NoMoreDocuments + SYMBOL;

export const PersonalAreaIllustration = PersonalArea + SYMBOL;

export const HelpIllustration = Help + SYMBOL;

export const ForbiddenIllustration = Forbidden + SYMBOL;

export const MailIllustration = Mail + SYMBOL;

export const CertificatesReadyIllustration = CertificatesReady + SYMBOL;

export const NewDocsIllustration = NewDocs + SYMBOL;

export const AcceptanceAgreementIllustration = AcceptanceAgreement + SYMBOL;

export const UploadedIllustration = Uploaded + SYMBOL;

export const SignSuccessIllustration = SignSuccess + SYMBOL;

export const MonitorIllustration = Monitor + SYMBOL;

export const FileIllustration = File + SYMBOL;

export const GoalIllustration = Goal + SYMBOL;

export const UnknownDocumentIllustration = UnknownDocument + SYMBOL;

export const IncomDocsIllustration = IncomDocs + SYMBOL;

export const ClockIllustration = Clock + SYMBOL;

export const EditDocumentIllustration = EditDocument + SYMBOL;

export const ErrorIllustration = Error + SYMBOL;

export const AsideIllustration = Aside + SYMBOL;

export const DocumentPlaceholderIllustration = DocumentPlaceholder + SYMBOL;

export const RefreshPageIllustration = RefreshPage + SYMBOL;

export const WrongDocumentIllustration = WrongDocument + SYMBOL;

export const ServerErrorIllustration = ServerError + SYMBOL;

export const RepairIllustration = Repair + SYMBOL;

export const NotFoundIllustration = NotFound + SYMBOL;

export const NoLicenseIllustration = NoLicense + SYMBOL;

export const LogoTextIllustration = LogoText + SYMBOL;

export const AppLogoIllustration = AppLogo + SYMBOL;

export const AngledLogoIllustration = AngledLogo + SYMBOL;
