import { checkMediaQuery } from '@utils';

import { useThrottledValue } from '../useThrottledValue';
import { useResizeObserver } from '../useResizeObserver';

/**
 * хук проверки соответствия размеру экрана, обновляется при изменении размера экрана
 * @example useMediaQuery(['(min-width: 1920px)', '(min-width: 768px)'])
 */
export const useMediaQuery = (queries: string[]) => {
  const [isSatisfy, handleResize] = useThrottledValue({
    sizeCb: () => queries.map((query) => checkMediaQuery(query)),
    throttleTime: 20,
  });

  useResizeObserver({
    sizeCb: handleResize,
    ref: { current: globalThis.document.body },
  });

  return isSatisfy;
};
