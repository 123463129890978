import { useForm } from '@hooks/useForm';
import { MAP_OF_TEXT_LENGTH } from '@constants';

import { createSchema, maxString, string } from '../../../../schemas/schema';
import { type FormCellCommonProps, type FormCellToggleProps } from '../types';
import { CellInner } from '../CellInner';
import { FormInput } from '../../FormInput';
import { createSubmitHandler } from '../utils';

const schema = createSchema({
  value: string(maxString(MAP_OF_TEXT_LENGTH.sm)),
});

export type ExternalCellTextInnerProps = FormCellCommonProps & {
  validationSchema?: typeof schema;
};

type CellTextInnerProps = ExternalCellTextInnerProps & FormCellToggleProps;

/**
 * внутренняя часть реализации обычного текстового поля
 */
export const CellTextInner = ({
  onSubmit,
  value,
  isLoading,
  onSuccess,
  onCancel,
  validationSchema = schema,
}: CellTextInnerProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: { value: value! },
    validateSchema: validationSchema,
    onSubmit: createSubmitHandler({ onSuccess, onSubmit }),
  });

  return (
    <CellInner
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onCancel={onCancel}
      isDirty={isDirty}
    >
      <FormInput
        fullWidth
        size="small"
        aria-label="value"
        hiddenLabel
        control={control}
        name="value"
      />
    </CellInner>
  );
};
