import {
  OverflowTypography,
  type OverflowedTypographyProps,
} from '../../../../OverflowTypography';
import { Skeleton } from '../../../../Skeleton';

export type HeaderTitleProps = {
  /**
   * @default 'h3'
   */
  variant?: OverflowedTypographyProps['variant'];
  className?: string;
  text?: string;
  isLoading?: boolean;
  hidePersonalData?: boolean;
};

export const HeaderTitle = ({
  variant = 'h3',
  text,
  className,
  isLoading,
  hidePersonalData,
}: HeaderTitleProps) => {
  if (!(text || isLoading)) {
    return null;
  }

  return (
    <OverflowTypography
      rowsCount={2}
      variant={variant}
      className={className}
      hidePersonalData={hidePersonalData}
    >
      {isLoading ? <Skeleton width={300} /> : text}
    </OverflowTypography>
  );
};
