import {
  type EndpointParams,
  type ReplaceRequestBody,
  type TypicalFilterParams,
  convertBase64ToFileBlob,
} from '@shared';

import { cacheService } from '../../services';
import { tenantsManagementClient as client, sagasClient } from '../clients';

export {
  type TenantDto,
  type AddTenantDto,
  type TenantSettingsDto,
} from '../../endpoints/tenantManagement';

export { type CreateTenantAdminSagaDto } from '../../endpoints/sagas';

export const tenantsManagementFetcher = {
  card: cacheService.createQuerySet((tenantId: string) => ({
    execute: () => client.tenants.tenantsGetTenantById({ tenantId }),
  })),
  list: cacheService.createInfiniteQuerySet(
    (params?: TypicalFilterParams, isOnPremOnly?: boolean) => ({
      execute: (infiniteParams) =>
        client.tenants.tenantsGetTenantsList({
          ...infiniteParams,
          findText: params?.findText,
          onPremOnly: isOnPremOnly,
        }),
    }),
  ),
  csv: cacheService.createQuerySet((fileName: string) => ({
    execute: () =>
      client.tenants
        .tenantsExportTenants()
        .then((res: string) => convertBase64ToFileBlob(res, fileName)),
  })),
  settings: cacheService.createQuerySet((tenantId: string) => ({
    execute: () =>
      client.tenantSettings.tenantSettingsGetTenantSettingsById({ tenantId }),
  })),
  add: cacheService.createMutationSet(
    (
      params: ReplaceRequestBody<
        EndpointParams<typeof client.tenants.tenantsAddTenant>
      >,
    ) => client.tenants.tenantsAddTenant({ requestBody: params }),
  ),
  addAdmin: cacheService.createMutationSet(
    (
      params: ReplaceRequestBody<
        EndpointParams<
          typeof sagasClient.client.addTenantAdminSagas.addTenantAdminSagasAddTenantAdmin
        >
      >,
    ) =>
      sagasClient.client.addTenantAdminSagas.addTenantAdminSagasAddTenantAdmin({
        requestBody: params,
      }),
  ),
  licences: cacheService.createQuerySet((tenantId: string) => ({
    execute: () =>
      client.tenantLicenses.tenantLicensesGetLicenses({ tenantId }),
  })),
  addLicenses: cacheService.createMutationSet(
    ({
      tenantId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<
        typeof client.tenantLicenses.tenantLicensesIncreaseTenantNumberOfLicenses
      >
    >) =>
      client.tenantLicenses.tenantLicensesIncreaseTenantNumberOfLicenses({
        tenantId,
        requestBody: params,
      }),
  ),
  updateLicensesEnded: cacheService.createMutationSet(
    ({
      tenantId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<
        typeof client.tenantLicenses.tenantLicensesUpdateLicensesEnded
      >
    >) =>
      client.tenantLicenses.tenantLicensesUpdateLicensesEnded({
        tenantId,
        requestBody: params,
      }),
  ),
  updateTenantDocNotification: cacheService.createMutationSet(
    ({
      tenantId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<typeof client.tenants.tenantsUpdateTenantDocNotification>
    >) =>
      client.tenants.tenantsUpdateTenantDocNotification({
        tenantId,
        requestBody: params,
      }),
  ),
  updateTenantIdentificationType: cacheService.createMutationSet(
    ({
      tenantId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<
        typeof client.tenants.tenantsUpdateTenantIdentificationType
      >
    >) =>
      client.tenants.tenantsUpdateTenantIdentificationType({
        tenantId,
        requestBody: params,
      }),
  ),
  updateSettings: cacheService.createMutationSet(
    ({
      tenantId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<
        typeof client.tenantSettings.tenantSettingsUpdateTenantSettings
      >
    >) =>
      client.tenantSettings.tenantSettingsUpdateTenantSettings({
        tenantId,
        requestBody: params,
      }),
  ),
  updateAffiliate: cacheService.createMutationSet(
    ({
      tenantId,
      ...params
    }: ReplaceRequestBody<
      EndpointParams<typeof client.tenants.tenantsUpdateAffiliate>
    >) =>
      client.tenants.tenantsUpdateAffiliate({ tenantId, requestBody: params }),
  ),
};

export type TenantsManagementFetcher = typeof tenantsManagementFetcher;
