/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalIntegrationTokenDto } from '../models/ExternalIntegrationTokenDto';
import type { ExternalIntegrationTokenInfoDto } from '../models/ExternalIntegrationTokenInfoDto';

import type { CancelablePromise } from '@common/data/api-core/CancelablePromise';
import type { BaseHttpRequest } from '@common/data/api-core/BaseHttpRequest';

export class ExternalIntegrationTokensService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Метод получения нового токена клиентом
   * @returns ExternalIntegrationTokenDto
   * @throws ApiError
   */
  public externalIntegrationTokensGenerateIntegrationToken({
    temporaryToken,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Временный токен **/
    temporaryToken?: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<ExternalIntegrationTokenDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/identity/ExternalIntegrationTokens/New',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        temporaryToken: temporaryToken,
      },
    });
  }

  /**
   * Метод получения списка выданных токенов
   * @returns ExternalIntegrationTokenInfoDto
   * @throws ApiError
   */
  public externalIntegrationTokensGetIntegrationTokens({
    subjectId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** Идентификатор субъекта, которому выданы токены **/
    subjectId?: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<Array<ExternalIntegrationTokenInfoDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/identity/ExternalIntegrationTokens',
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
      query: {
        subjectId: subjectId,
      },
    });
  }

  /**
   * Метод для отзыва токена
   * @returns any
   * @throws ApiError
   */
  public externalIntegrationTokensRevokeTenantToken({
    tokenId,
    employeeworkplaceid,
    kedoGatewayTokenType,
  }: {
    /** ID отзываемого токена **/
    tokenId: string;
    /** Guid идентификатор рабочего места сотрудника. Обязательность поля определяется обработчиком при исполнении. Требуется только для Web приложения **/
    employeeworkplaceid?: any;
    kedoGatewayTokenType?: any;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/identity/ExternalIntegrationTokens/{tokenId}',
      path: {
        tokenId: tokenId,
      },
      headers: {
        employeeworkplaceid: employeeworkplaceid,
        'kedo-gateway-token-type': kedoGatewayTokenType,
      },
    });
  }
}
