import { type WithJSXChildren } from '@types';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from '@constants';

import { MediaQueryContext } from '../MediaQueryContext';

type IsMobileContextProviderProps = WithJSXChildren;

export const MediaQueryContextProvider = ({
  children,
}: IsMobileContextProviderProps) => {
  const [isMobile, isTablet] = useMediaQuery([
    MOBILE_MEDIA_QUERY,
    TABLET_MEDIA_QUERY,
  ]);

  return (
    <MediaQueryContext.Provider value={{ isMobile, isTablet }}>
      {children}
    </MediaQueryContext.Provider>
  );
};
