import { useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import {
  ConfigProvider,
  ContentLoader,
  GlobalStyles,
  MediaQueryContextProvider,
  MetaTitleContainer,
  NotificationContainer,
  RouterAdapter,
  ThemeProvider,
  makeTheme,
} from '@dashboard/shared';
import { LayoutSwitcher } from '@dashboard/modules/Layout';
import { authStore } from '@dashboard/modules/Auth';
import {
  configService,
  errorMonitoringService,
} from '@dashboard/modules/service';

import { routes } from './routes';

const uiTheme = makeTheme(configService.data);

export const App = observer(() => {
  const renderRoutes = useRoutes(routes(authStore.isLoggedIn));

  return (
    <MediaQueryContextProvider>
      <ConfigProvider captureException={errorMonitoringService.capture}>
        <ThemeProvider theme={uiTheme}>
          <GlobalStyles styles={{ body: { width: '100vw' } }} />
          <RouterAdapter />
          <LayoutSwitcher>
            <Suspense fallback={<ContentLoader />}>{renderRoutes}</Suspense>
          </LayoutSwitcher>
          <MetaTitleContainer />
          <NotificationContainer />
        </ThemeProvider>
      </ConfigProvider>
    </MediaQueryContextProvider>
  );
});
