import { useForm } from '@hooks/useForm';
import { MAP_OF_TEXT_LENGTH } from '@constants';

import { createSchema, maxString, string } from '../../../../schemas/schema';
import { type FormCellCommonProps, type FormCellToggleProps } from '../types';
import { CellInner } from '../CellInner';
import { createSubmitHandler } from '../utils';
import type { SelectProps } from '../../../Select';
import { FormSelect } from '../../FormSelect';

export type CellSelectInnerProps = FormCellCommonProps &
  FormCellToggleProps & {
    selectOptions?: SelectProps['options'];
  };

const schema = createSchema({
  value: string(maxString(MAP_OF_TEXT_LENGTH.sm)),
});

/**
 * внутренняя часть для реализации варианта с селектом
 */
export const CellSelectInner = ({
  onSubmit,
  value,
  isLoading,
  onSuccess,
  onCancel,
  selectOptions = [],
}: CellSelectInnerProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: { value: value! },
    validateSchema: schema,
    onSubmit: createSubmitHandler({ onSuccess, onSubmit }),
  });

  return (
    <CellInner
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onCancel={onCancel}
      isDirty={isDirty}
    >
      <FormSelect
        options={selectOptions}
        fullWidth
        size="small"
        aria-label="value"
        hiddenLabel
        control={control}
        name="value"
      />
    </CellInner>
  );
};
